import React from "react";
import * as styles from "./terms-and-conditions-of-use.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {TermsAndConditionsOfUseQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: TermsAndConditionsOfUseQuery
}

const TermsAndConditionsOfUsePage:React.FC<ReactProps> = ({data}) => {

    const isMobile = useWindowWidth() <= 1200;

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsWebGeneralConditionUse.seo}         pageTemplate="conditions d'utilisation">
            <PageCover
                title={data.datoCmsWebGeneralConditionUse.cover[0] && data.datoCmsWebGeneralConditionUse.cover[0].title}
                image={data.datoCmsWebGeneralConditionUse.cover[0] && data.datoCmsWebGeneralConditionUse.cover[0].backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsWebGeneralConditionUse.linksGroup} />}
                    <div className={styles.content}>
                        <RichDatoCmsContent data={data.datoCmsGeneralconditionutilisation.content} />
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsWebGeneralConditionUse.linksGroup} />}
                </div>
            </section>
        </Layout>
    )

}

export const fragment = graphql`
    fragment TermsAndConditionsOfUseWebFields on DatoCmsWebGeneralConditionUse {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
    }
    
    fragment TermsAndConditionsOfUseFields on DatoCmsGeneralconditionutilisation {
        content {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsStatisticsBloc {
                ...StatisticsBlockFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ... on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
    }
`

export const pageQuery = graphql`
    query TermsAndConditionsOfUse($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsGeneralconditionutilisation(locale: {  eq: $locale }) {
            ...TermsAndConditionsOfUseFields
        }
        datoCmsWebGeneralConditionUse(locale: {  eq: $locale }) {
            ...TermsAndConditionsOfUseWebFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default TermsAndConditionsOfUsePage;